// Google Tag Manager Class to be injected
// import Cookies from 'js-cookie'

// TODO: check if port to https://nuxt.com/modules/nuxt-gtm is possible

import type {
    CheckoutMutation_ManageCheckout_Checkout,
    CheckoutMutation_ManageCheckout_Checkout_Extended,
    CheckoutMutation_ManageCheckout_Checkout_Extended_LineItem,
    CheckoutMutation_ManageCheckout_Checkout_Extended_LineItems,
    GTMCartItem,
    GTMEventName,
    GTMEventPayload,
    GTMFormEvent,
    GTMFormEventDetails,
} from '~/@types/nestedGraphqlTypes';
import { CurrencyCode } from '~/graphql/generated';

import type { GTMModuleOptions } from '~/modules/gtm';

// const searchEngines = [
//     'www.google',
//     'www.bing',
//     'www.yandex',
//     'search.yahoo',
//     'duckduckgo',
//     'www.ask',
//     'www.search',
//     'www.info',
//     'www.baidu',
//     'search.aol',
//     'www.dogpile',
// ];
// let firstCall = false

type CTXType = any; //

// only executed client side
const getNativeVariantId = (id: string) => {
    return atob(id).substring('gid://shopify/ProductVariant/'.length);
};

const getNativeProductId = (id?: string | null) => {
    return id?.substring('gid://shopify/Product/'.length) || '';
};

type EcommerceItem = (
    | {
          item_id: string;
          item_name?: string;
      }
    | {
          item_id?: string;
          item_name: string;
      }
) & {
    affiliation?: string;
    discount?: number;
    index?: number;
    item_brand?: string;
    item_category?: string;
    item_category2?: string;
    item_category3?: string;
    item_category4?: string;
    item_category5?: string;
    // item_list_id?: string; // => we set it on the event level
    // item_list_name?: string; //  => we set it on the event level
    item_variant?: string;
    location_id?: string;
    price?: number;
    quantity?: number;
};

type GA4SelectItemPayload = {
    item_list_id?: string;
    item_list_name?: string;
    items: EcommerceItem[];
};

export type GA4SelectContentPayload = { content_type: string; content_id: string } & Record<string, string | number>;

type S2SGTMEventPayload = GTMEventPayload & { event_name?: string; lang?: string; path?: string };

// TODO: udate with tracking-helper stuff
export class GTM {
    ctx: CTXType;
    options: GTMModuleOptions;
    constructor(ctx: CTXType, options: GTMModuleOptions) {
        this.ctx = ctx;
        this.options = options;
        // @ts-ignore
        window[this.options.layer] = window[this.options.layer] || [];
        this.pushEvent({
            event: 'gtm.js',
            'gtm.start': new Date().getTime(),
        });
    }

    #getWindowLayer() {
        // @ts-ignore
        return window[this.options.layer];
    }

    #numericPrice(p?: number | string, fallback = 0) {
        if (typeof p === 'string') {
            try {
                return Number.parseFloat(p);
            } catch (err) {}
        }
        return p || fallback;
    }

    #parseTextPrice(price: string) {
        let bundlePrice = price.replace(/[^0-9.,]/gi, '');
        const dot = bundlePrice.indexOf('.');
        const comma = bundlePrice.indexOf(',');
        if (comma > 0) {
            if (dot > 0 && dot < comma) {
                bundlePrice = bundlePrice.replace(/[.]/gi, '').replace(/[,]/, '.');
            } else if (dot > 0 && dot > comma) {
                bundlePrice = bundlePrice.replace(/[,]/gi, '');
            } else {
                bundlePrice = bundlePrice.replace(/[,]/gi, '.');
            }
        }
        try {
            const ret = Number.parseFloat(bundlePrice);
            return Number.isNaN(ret) ? 0 : ret;
        } catch (err) {
            return 0;
        }
    }

    #calcBreadcrumbs(page: PageByUrlQuery_PageByUrl_Page) {
        if (page.breadcrumbs) {
            return page.breadcrumbs
                ?.map((b: any) => {
                    return b.breadcrumb?.replace(/(%\(|\)%|%{|}%|%\[|\]%)/gi, '');
                })
                .join(' > ');
        }
        return '';
    }

    /**
     * returns the item categories for the ecommerce object
     */
    #getItemCategories(category: string | null | undefined) {
        const categories: FixedLengthArray<[string, string]> = !category
            ? ['', '']
            : ['original', 'now', 'up', 'off', 'explore'].includes(category.toLowerCase())
              ? ['Bikes', category]
              : [category, ''];
        return {
            item_category: categories[0],
            item_category2: categories[1],
            item_category3: '',
            item_category4: '',
            item_category5: '',
        };
    }

    #cmsProductToEcommerceObject(product: CMSProduct, index: number) {
        const currency: CurrencyCode = product.currency || CurrencyCode.Eur;
        const variant = product.variants.items[0];
        return {
            item_name: product.title, // Name or ID is required.
            item_id: variant.sku,
            currency,
            price: product.priceNumeric ? product.priceNumeric : 0,
            index,
            barcode: variant.barcode,
            item_brand: 'woom',
            ...this.#getItemCategories(product.productType),
            item_variant: variant.variantTitle,
            item_image: product.image,
            quantity: 1,
        };
    }

    #processCartItem(li: CheckoutMutation_ManageCheckout_Checkout_Extended_LineItem, idx: number, quantity: number): GTMCartItem {
        let bundleName = '';
        let bundlePrice = 0;
        const bundlePriceDef = li.customAttributes.find((p: any) => p.key === '_bundle_price');
        if (bundlePriceDef) {
            bundlePrice = this.#parseTextPrice(bundlePriceDef.value);
        }
        const handle = li.variant?.productRef ? li.variant.productRef.substring(li.variant.productRef.lastIndexOf(':') + 1) : '';
        const bundleNameDef = li.customAttributes.find((p: any) => p.key === 'Bundle');
        if (bundleNameDef) {
            bundleName = bundleNameDef.value;
        }
        let discount = 0;
        // TODO: will have to change with the new storefront cart api
        if (Array.isArray(li.discountAllocations)) {
            for (const d of li.discountAllocations) {
                if (d.discountedAmount && d.discountedAmount.amount) {
                    const p = this.#parseTextPrice(d.discountedAmount.amount);
                    if (p) {
                        discount += p / (li.quantity || 1);
                    }
                }
            }
            discount = Math.round(discount * 100) / 100;
        }
        const price = this.#numericPrice(li.variant?.price?.amount || '');
        const productID = getNativeProductId(li.nativeProductId);
        return {
            /* eslint-disable camelcase */
            item_name: li.shopTitle, // Name or ID is required.
            item_id: li.variant?.sku,
            currency: li.variant?.price?.currencyCode,
            price: price,
            priceString: typeof price === 'string' ? price : price?.toFixed(2),
            discount,
            quantity,
            image: li.variant?.shopImage,
            sku: li.variant?.sku || '',
            index: idx,
            bundle_name: bundleName,
            bundle_price: bundlePrice,
            barcode: li.variant?.barcode,
            item_brand: 'woom',
            ...this.#getItemCategories(li.variant?.productType),
            item_variant: li.variant?.shopTitle === 'Default Title' ? '' : li.variant?.shopTitle,
            /** for klaviyo */
            handle,
            product_type: li.variant?.productType || 'undefined type',
            product_id: productID,
            product_title: li.shopTitle || 'undefined title',
            variant_id: li.variant?.nativeId ? getNativeVariantId(li.variant?.nativeId) : '',
            /* eslint-enable camelcase */
        };
    }

    #ecommerceClear() {
        this.pushEvent({ ecommerce: null, event: 'ecommerceClear' });
    }

    /**
     * When a user is presented with a list of results
     * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#select_an_item_from_a_list
     */
    ecommerceViewItemList(listId: string, listName: string, products: CMSProduct[]) {
        // TODO: Update deprecated .server, .client etc
        if (process.server || !Array.isArray(products) || products.length === 0) return;
        const items = products.map((product) => {
            const price = this.#numericPrice(product.priceNumeric);
            const variant = product.variants.items[0]; // Pick the first variant
            return {
                item_id: variant?.sku, // TODO: Add fallback SKU for other product types
                item_name: product.title,
                price,
                ...this.#getItemCategories(product.productType),
            };
        });
        this.#ecommerceClear();
        this.pushEvent({
            event: 'view_item_list',
            ecommerce: {
                currency: products[0].currency,
                item_list_id: listId,
                item_list_name: listName,
                items,
            },
        });
    }

    /**
     * Enhanced measurement events
     * https://support.google.com/analytics/answer/9216061
     */
    ecommerceFormInteractions(event: GTMFormEvent, event_details: GTMFormEventDetails) {
        this.#ecommerceClear();
        this.pushEvent({
            event: event,
            ecommerce: {
                event_details: event_details,
            },
        });
    }

    ecommerceProductList(listId: string, listLabel: string, products: CMSProduct[]) {
        if (Array.isArray(products) && products.length > 0) {
            this.#ecommerceClear();
            this.pushEvent({
                event: 'view_item_list',
                ecommerce: {
                    item_list_id: listId,
                    item_list_name: listLabel,
                    items: products.map(this.#cmsProductToEcommerceObject),
                },
            });
        }
    }

    // TODO: products should be CMSProduct[]
    ecommerceBundlePage(page: PageByUrlQuery_PageByUrl_Page | undefined, products: PageByUrlQuery_PageByUrl_Page_Bundle_Products) {
        if (!page?.bundle || !products?.length) return;
        const price = this.#parseTextPrice(page?.bundle?.price || '');
        // parse the price label from bundle

        // console.log('ecom gtm page view', page, product, variant)
        const first = products[0];
        const currency = first.variants.items.find((x: any) => x).price.currencyCode || 'USD';
        // console.log('first product', first, currency)
        const cats = ['Bundle', '', '', '', ''];
        const shid = page.bundle.code;
        this.#ecommerceClear();
        // this will be a single product call for the bundle
        let img = null;
        const imgBase = page.bundle.cartImage?.data.image || '';
        if (imgBase !== '') {
            img = `${page.bundle.cartImage?.data.baseUrl}${imgBase}`;
        }
        const bundleLabel = page.bundle.label || 'Undefined Bundle';

        this.pushEvent({
            event: 'productView',
            ecommerce: {
                currency,
                detail: {
                    actionField: { list: 'Product Detail Page' }, // Optional list property.
                    products: [
                        {
                            /* eslint-disable camelcase */
                            name: bundleLabel, // Name or ID is required.
                            id: shid,
                            price: `${price.toFixed(2)}`,
                            brand: 'woom',
                            category: 'Bundle',
                            item_variant: '',
                            image: img,
                            categories: cats, // [],
                            /* eslint-enable camelcase */
                        },
                    ],
                },
            },
            shopProductId: shid,
        });

        this.#ecommerceClear();
        // now ga4 - this will contain the breakdown with all bundle products
        const items = [];
        let idx = 0;
        for (const product of products) {
            const variant = product.variants.items.find((x: any) => x) || null;
            if (variant) {
                items.push({
                    /* eslint-disable camelcase */
                    item_name: product.shopTitle, // Name or ID is required.
                    item_id: variant.sku,
                    currency,
                    price: this.#numericPrice(variant.price.amount),
                    index: idx,
                    bundle_name: bundleLabel,
                    bundle_price: price,
                    barcode: variant.barcode,
                    item_brand: 'woom',
                    ...this.#getItemCategories(product.productType),
                    item_variant: '',
                    /* eslint-enable camelcase */
                });
                idx += 1;
            }
        }
        this.pushEvent({
            event: 'view_item',
            ecommerce: {
                currency,
                value: price,
                items,
            },
            shopProductId: shid,
        });

        this.pushEvent({
            event: 'emarsys_bundle',
            emarsys: {
                category: this.#calcBreadcrumbs(page),
            },
        });
    }

    ecommerceContentPage(page: PageByUrlQuery_PageByUrl_Page | undefined) {
        if (!page) return;
        this.pushEvent({
            event: 'emarsys_content',
            emarsys: {
                category: this.#calcBreadcrumbs(page),
            },
        });
    }

    ecommerceProductPage(page: PageByUrlQuery_PageByUrl_Page | undefined, product: CMSProduct | undefined) {
        if (!product || !page?.product) return;

        const variant = product.variants?.items?.find(isDefined);
        if (!variant) return;

        const price = page.product.priceRange?.minVariantPrice?.amount || '';
        const currency = page.product.priceRange?.minVariantPrice?.currencyCode || CurrencyCode.Eur;
        // does not exist - but sku does on variant?
        /**
         * Needed for Klaviyo
         */
        let shid = variant.sku;
        if (product.nativeId) {
            // we operate on client side - rather use atob
            shid = atob(product.nativeId);
            shid = shid.substring(22);
            // console.log('product', product.nativeId, shid);
        }
        this.#ecommerceClear();
        this.pushEvent({
            event: 'productView',
            ecommerce: {
                currency,
                detail: {
                    actionField: { list: 'Product Detail Page' }, // Optional list property.
                    products: [
                        {
                            name: page.product.shopTitle, // Name or ID is required.
                            id: variant.sku,
                            price,
                            brand: 'woom',
                            category: product.productType,
                            item_variant: variant.variantTitle,
                            image: page.product?.shopImage,
                            categories: page.product.collections.map((collection) => collection?.handle).filter(isDefined) ?? [],
                        },
                    ],
                },
            },
            shopProductId: shid,
        });
        this.#ecommerceClear();
        // now ga4
        this.pushEvent({
            event: 'view_item',
            ecommerce: {
                currency,
                value: this.#numericPrice(price),
                items: [this.#cmsProductToEcommerceObject(product, 0)],
            },
        });
        this.pushEvent({
            event: 'emarsys_pdp',
            emarsys: {
                category: this.#calcBreadcrumbs(page),
            },
        });
    }

    viewCart(cart: CheckoutMutation_ManageCheckout_Checkout) {
        if (!cart) return;
        this.#ecommerceClear();
        this.pushEvent({
            event: 'view_cart',
            ecommerce: {
                currency: cart.totalPrice?.currencyCode || CurrencyCode.Usd,
                value: this.#numericPrice(cart.totalPrice?.amount || 0),
                items: cart.lineItems.map((lineItem, index) => this.#processCartItem(lineItem, index, lineItem.quantity || 1)),
            },
        });
    }

    cartPage(cart: CheckoutMutation_ManageCheckout_Checkout) {
        this.viewCart(cart);
        this.pushEvent({
            event: 'emarsys_cart',
            emarsys: {
                category: 'Cart',
            },
        });
    }

    // we only deal in single quantities when items are added
    addCartItems(
        items: CheckoutMutation_ManageCheckout_Checkout_Extended_LineItems,
        checkout: CheckoutMutation_ManageCheckout_Checkout,
        componentOrigin?: string,
    ) {
        if (!checkout) return;
        if (!Array.isArray(items) || items.length === 0) return;
        // console.log('add cart items', items)
        const currency = items[0].variant?.price?.currencyCode || '';
        const { newItems, price } = items.reduce(
            (accumulator, item, index) => {
                const data = this.#processCartItem(item, index, 1);
                const numericPrice = this.#numericPrice(data.price);
                if (typeof numericPrice === 'number') {
                    let discount = 0;
                    if (data.discount) {
                        discount = typeof data.discount === 'string' ? parseInt(data.discount) : data.discount;
                    }
                    accumulator.price += numericPrice - discount;
                }
                accumulator.newItems.push(data);
                return accumulator;
            },
            { newItems: [] as GTMCartItem[], price: 0 },
        );

        // adding all total items
        const allItems = checkout.lineItems.map((lineItem, index) => this.#processCartItem(lineItem, index, 1));
        const lineItemPrice = checkout?.lineItemsSubtotalPrice ? this.#numericPrice(checkout.lineItemsSubtotalPrice.amount) : 0;
        const total = checkout?.totalPrice ? this.#numericPrice(checkout.totalPrice.amount) : 0;
        let discount = 0;
        if (typeof lineItemPrice === 'number' && typeof total === 'number') {
            discount = Math.round((lineItemPrice - total + Number.EPSILON) * 100) / 100;
        }

        this.#ecommerceClear();
        this.pushEvent({
            event: 'add_to_cart',
            ecommerce: {
                currency,
                value: price,
                items: newItems,
                all: allItems,
                basket_size: checkout.totalQuantity,
                basket_value: total,
                basket_total_discount: discount,
                basket_original_total_price: lineItemPrice,
                checkoutUrl: checkout.webUrl,
                component_origin: componentOrigin,
            },
        } as GTMEventPayload);
        if (items?.length === 1) {
            const checkoutId = checkout.nativeCheckoutId ? checkout.nativeCheckoutId.substring('gid://shopify/Checkout/'.length) : checkout.id;
            const productID = getNativeProductId(items[0].nativeProductId);
            this.pushEvent({
                event: 'add_triple_whale',
                tw: {
                    item: productID,
                    q: 1,
                    token: checkoutId,
                },
            });
        }
    }

    // we only deal with single quantities when items are removed
    removeCartItems(items: CheckoutMutation_ManageCheckout_Checkout_Extended_LineItems) {
        if (Array.isArray(items) && items.length > 0) {
            let price = 0;
            const currency = items[0].variant?.price?.currencyCode || '';
            let idx = 0;
            const newItems: GTMCartItem[] = [];
            for (const cartItem of items) {
                const cartItemData = this.#processCartItem(cartItem, idx, 1);
                const numericPrice = this.#numericPrice(cartItemData.price);
                if (typeof numericPrice === 'number') {
                    price +=
                        numericPrice - (typeof cartItemData.discount === 'string' ? parseInt(cartItemData.discount) : cartItemData.discount || 0);
                }
                newItems.push(cartItemData);
                idx += 1;
            }
            this.#ecommerceClear();
            this.pushEvent({
                event: 'remove_from_cart',
                ecommerce: {
                    currency,
                    value: price,
                    items: newItems,
                },
            } as GTMEventPayload);
        }
    }

    /**
     * provides the cart object to the dataLayer
     */
    updateCart(objIn: CheckoutMutation_ManageCheckout_Checkout_Extended | null) {
        const obj: CheckoutMutation_ManageCheckout_Checkout_Extended = {
            event: 'cart_update',
            cart: {
                /* eslint-disable camelcase */
                items: [],
                basket_size: 0,
                basket_value: 0,
                currency: null,
                /* eslint-enable camelcase */
            },
        };

        if (objIn) {
            this.pushEvent({
                event: 'cart-clear',
                cart: null,
            });
            obj.cart = {
                items: (objIn.lineItems || []).map((lineItem, index) => this.#processCartItem(lineItem, index, lineItem.quantity || 1)),
                basket_size: objIn.totalQuantity,
                basket_value: objIn.totalPrice?.amount ? this.#numericPrice(objIn.totalPrice.amount) : 0,
                currency: objIn.totalPrice ? objIn.totalPrice.currencyCode : null,
            };
        }
        this.pushEvent(obj);
    }

    /**
     * Triggers GA4 standard event `select_content`
     *
     * 📘 [Event docs](https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#select_content)
     */
    selectContent(data: GA4SelectContentPayload) {
        this.pushEvent({
            event: 'select_content',
            ...data,
        });
    }
    /**
     * Triggers GA4 standard event `select_item`
     *
     * 📘 [Event docs](https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#select_item)
     */
    selectItem(ecommerce: GA4SelectItemPayload) {
        this.#ecommerceClear();
        this.pushEvent({
            event: 'select_item',
            ecommerce,
        });
    }

    async pushEvent(obj: GTMEventPayload) {
        try {
            if (!this.#getWindowLayer()) {
                throw new Error('missing GTM dataLayer');
            }
            if (typeof obj !== 'object') {
                throw new TypeError('event should be an object');
            }
            if (!obj.ecommerce) {
                if (!obj.event) {
                    throw new Error('missing event property');
                }
            }
            this.#getWindowLayer().push(obj);

            const cloudflareWorkerUrl = this.ctx.cfCookieWorkerUrl;

            if (!cloudflareWorkerUrl) return;

            const s2sObj: S2SGTMEventPayload = obj;
            // S2S - sending data to the session cookie worker
            s2sObj.event_name = obj.event; // S2S uses "event_name" instead of "event"
            s2sObj.lang = this.ctx.lang;
            s2sObj.path = this.ctx.path;
            try {
                await $fetch(this.ctx.cfCookieWorkerUrl, {
                    method: 'POST',
                    body: s2sObj,
                    headers: { 'Content-type': 'application/json' },
                    credentials: 'include',
                });
            } catch (e) {
                console.error(`sending tracking data error: ${(e as Error).message ?? JSON.stringify(e)}`);
            }
        } catch (err) {
            console.error('[ERROR] [GTM]', err);
        }
    }
}

export default defineNuxtPlugin((nuxtApp) => {
    // in order to access the gtm config client side (including env variables), we need to access it via the
    // public configuration in the runtime config
    const config = useRuntimeConfig();
    const route = useRoute();

    const {
        $i18n: { locale },
    } = useNuxtApp();
    const ctx: any = { cfCookieWorkerUrl: config.public.cfCookieWorkerUrl, lang: locale.value.split('_')[0], path: route.path };

    const options: GTMModuleOptions = config.public?.gtm;
    console.log('gtm get config', config);
    if (!options) {
        console.log('gtm load - no options present: abort');
        return;
    }

    if (config.public.nodeEnv !== 'production' && !options.dev) {
        console.log('gtm - neither production nor a options.dev flag');
        return;
    }

    console.log('inject gtm');
    const $gtm = new GTM(ctx, options);

    nuxtApp.provide('gtm', $gtm);
});
