<script setup lang="ts">
    import { dragscroll } from 'vue-dragscroll';
    import type { BreadcrumbVisibility } from '~/@types/cms';

    interface BreadcrumbsProps {
        items: {
            title: string;
            slug: string;
        }[];
        visibility: BreadcrumbVisibility;
    }
    const props = defineProps<BreadcrumbsProps>();

    const localePath = useLocalePathWoom();

    const isLastBreadcrumb = (index: number) => index + 1 === props.items.length;

    const vDragscroll = dragscroll;
</script>
<template>
    <div :class="['atm-breadcrumbs overflow-hidden', { 'max-md:hidden': visibility === 'none_mobile' }]">
        <ul
            v-if="Array.isArray(items) && items.length"
            v-dragscroll.x
            class="scrollbar flex h-14 items-center gap-2 overflow-x-auto font-mono text-sm">
            <!-- padding on the first and last list items for an enhanced content view during scrolling -->
            <li
                v-for="(item, index) in items"
                :key="index"
                class="flex items-center gap-2 first:pl-6 last:pr-6 first:md:pl-8 last:md:pr-8">
                <nuxt-link
                    :to="isLastBreadcrumb(index) ? '#main-content' : localePath(item.slug)"
                    :aria-current="isLastBreadcrumb(index) ? 'page' : undefined"
                    :class="[{ 'text-woom-red hover:no-underline': isLastBreadcrumb(index) }, 'whitespace-nowrap hover:underline']">
                    <renderer-html :content="woomTextFormat(item.title)" />
                </nuxt-link>
                <woom-icon-chevron-right
                    v-if="!isLastBreadcrumb(index)"
                    width="14"
                    height="14" />
            </li>
        </ul>
    </div>
</template>
