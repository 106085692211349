<script setup lang="ts">
    import type { PageProps } from '~/@types/generic';

    const props = defineProps<PageProps>();

    const { breadcrumbItems, breadcrumbVisibility, page, pageId } = usePage(props);

    const nuxtApp = useNuxtApp();

    onMounted(() => {
        if (nuxtApp.$gtm) {
            nuxtApp.$gtm.ecommerceContentPage(page.value);
        }
    });
</script>

<template>
    <div class="page content-grid">
        <atm-breadcrumbs
            v-if="breadcrumbItems?.length > 1"
            :items="breadcrumbItems"
            :visibility="breadcrumbVisibility" />

        <cms-content-controller
            :data="page?.content"
            :page-id="pageId"
            is-content-page
            class="page-controller content-grid" />
    </div>
</template>
