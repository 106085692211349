import type { AssetData, AssetDataBynder, AssetDataBynderVideo, AssetDataKontentAi, AssetDataYouTubeVideo, AssetDataIcon } from '~/@types/cms';

export function getAssetUrl(imageName: string, category = 'products') {
    return `https://assets.woom.com/${category}/${imageName}.png`;
}

export const nuxtImageSizes = {
    carousel: '400 md:512 lg:720 2xl:1200',
};

export function isAssetBynder(asset: { id: string }): asset is AssetDataBynder {
    return asset.id.startsWith('b:');
}
export function isAssetDataBynderVideo(asset: { id: string }): asset is AssetDataBynderVideo {
    return asset.id.startsWith('video:');
}
export function isAssetDataYouTubeVideo(asset: { id: string }): asset is AssetDataYouTubeVideo {
    return asset.id.startsWith('yt:');
}
export function isAssetKontentAi(asset: { id: string }): asset is AssetDataKontentAi {
    return asset.id.startsWith('a:');
}
export function isAssetDataIcon(asset: { id: string }): asset is AssetDataIcon {
    return asset.id.startsWith('icon:');
}
