export const giftcardFactoryChecksumKey = '_gcp_checksum';
export const giftcardFormValidKey = '_giftcardFormValid';

export const NotificationType = {
    ERROR: 'ERROR',
    INFO: 'INFO',
} as const;

export const WoomCookies = {
    WoomSession: 'woomsession',
} as const;

export const HTTPMethod = {
    GET: 'GET',
    PUT: 'PUT',
    POST: 'POST',
    DELETE: 'DELETE',
} as const;

export const ProductAvailability = {
    LOCKED: 'LOCKED',
    AVAILABLE: 'AVAILABLE',
    PREORDER: 'PREORDER',
    OUT_OF_STOCK: 'OUT_OF_STOCK',
    NOTIFY_ME: 'NOTIFY_ME',
    NOT_IN_THIS_REGION: 'NOT_IN_THIS_REGION',
    COMING_SOON: 'COMINGSOON',
} as const;

export const woomFoundingDateSring = '2013-03-12';

export const ProductQuantityAvailable = {
    LOW_STOCK: 10,
} as const;

/**
 * `Iwtstns` = "I want to see the new stuff"
 */
export const CACHE_UPDATE_PARAM = 'Iwtstns';

export const bikefinderHeightLimits = {
    lowerMetric: 82,
    upperMetric: 165,
    lowerImperial: 31,
    upperImperial: 66,
};
export const bikefinderInseamLimits = {
    lowerMetric: 20,
    upperMetric: 95,
    lowerImperial: 8,
    upperImperial: 40,
};
