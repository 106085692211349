/**
 * Utility for sending data to the KV Cloudflare storage for GTM use
 */
export default defineNuxtPlugin(async (nuxtApp) => {
    const config = useRuntimeConfig();
    const route = useRoute();
    const cloudflareWorkerUrl = config.public.cfCookieWorkerUrl;

    if (!cloudflareWorkerUrl) return;

    const postData: {
        emailAddress?: string | null;
        utmSource?: string | null;
        utmMedium?: string | null;
        utmContent?: string | null;
        utmTerm?: string | null;
        utmCampaign?: string | null;
    } = {};

    if (!!route.query.utm_source) {
        postData.utmSource = route.query.utm_source.toString();
    }
    if (!!route.query.utm_medium) {
        postData.utmMedium = route.query.utm_medium.toString();
    }
    if (!!route.query.utm_content) {
        postData.utmContent = route.query.utm_content.toString();
    }
    if (!!route.query.utm_term) {
        postData.utmTerm = route.query.utm_term.toString();
    }
    if (!!route.query.utm_campaign) {
        postData.utmCampaign = route.query.utm_campaign.toString();
    }

    try {
        await $fetch(cloudflareWorkerUrl, {
            method: 'POST',
            body: postData,
            headers: { 'Content-type': 'application/json' },
            credentials: 'include',
        });
    } catch (e) {
        console.error(`session cookie error: ${(e as Error).message ?? JSON.stringify(e)}`);
    }
});
