<script setup lang="ts">
    import { LazyCmsGoogleFormWidgetContainer } from '#components';
    import type { GoogleFormWidgetData } from '~/@types/cms';
    import type { ButtonStyling } from '~/components/atm/Button.vue';

    export interface LinkProps {
        link?: Link;
        styling?: ButtonStyling;
        colorBg?: string;
        colorLabel?: string;
        linkOnly?: boolean;
        textLink?: boolean;
        storage?: any;
    }

    const props = withDefaults(defineProps<LinkProps>(), {
        styling: 'solid',
    });

    const nuxtApp = useNuxtApp();
    const { t } = useLocales();

    const { generateStringOnlyURL } = useURL();

    const toURL = computed(() => {
        if (!props.link) return;
        return generateStringOnlyURL(props.link, props.storage);
    });

    const label = computed(() => {
        return props.link?.label ? woomTextFormat(props.link.label) : t('cta.readMore');
    });
    const target = computed(() => {
        return props.link?.type === 'url' ? props.link?.data.target || '_self' : '_self';
    });

    const handleClick = () => {
        if (props.textLink) return;
        if (props.link?.data && props.link?.type === 'googleform') {
            const formProps: IWidgetProps<GoogleFormWidgetData> = {
                data: {
                    id: props.link?.data?.form,
                    codename: '',
                    types: [],
                    language: '',
                    form: props.link?.data?.form,
                    success: props.link?.data?.success,
                    background: 'white',
                    fullWidth: true,
                },
            };
            nuxtApp.$eventEmitter.emit('open-drawer', {
                title: '',
                content: {
                    component: LazyCmsGoogleFormWidgetContainer,
                    props: formProps,
                },
            });
        }
    };
</script>
<template>
    <nuxt-link
        v-if="link?.clickType === 'link' || textLink"
        :to="toURL"
        :target="target"
        class="underline transition-colors hover:text-woom-red"
        @click="handleClick">
        <slot>
            <renderer-html :content="label" />
        </slot>
    </nuxt-link>
    <lazy-atm-button
        v-else-if="['form', 'googleform'].includes(`${link?.type}`)"
        :styling="styling"
        :color-bg="colorBg"
        :color-label="colorLabel"
        kind="button"
        @click="handleClick">
        <renderer-html :content="label" />
    </lazy-atm-button>
    <nuxt-link
        v-else-if="linkOnly"
        :to="toURL"
        :target="target">
        <!-- linkOnly used for large clickable areas with slot content like cards, notification etc -->
        <slot></slot>
    </nuxt-link>
    <lazy-atm-button
        v-else
        :styling="styling"
        :color-bg="colorBg"
        :color-label="colorLabel"
        :to="toURL"
        :target="target"
        kind="link"
        skip-local-path>
        <renderer-html :content="label" />
    </lazy-atm-button>
</template>
