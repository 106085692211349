<script setup lang="ts">
    import { NuxtLink } from '#components';

    export type ButtonStyling =
        | 'solid'
        | 'solid-secondary'
        | 'solid-invert'
        | 'outline'
        | 'outline-invert'
        | 'text'
        | 'text-secondary'
        | 'text-invert'
        | 'solid-custom'
        | 'outline-custom'
        | 'solid-rounded';

    export interface ButtonProps {
        kind?: 'link' | 'button' | 'decorative';
        to?: string | never;
        target?: '_self' | '_blank';
        styling?: ButtonStyling;
        size?: 'small' | 'medium';
        iconRight?: boolean;
        block?: boolean;
        loading?: boolean;
        disabled?: boolean;
        colorLabel?: string;
        colorBg?: string;
        noGtm?: boolean;
        skipLocalPath?: boolean;
        gtm?: any;
    }

    const props = withDefaults(defineProps<ButtonProps>(), {
        kind: 'button',
        to: '',
        target: '_self',
        styling: 'solid',
        size: 'medium',
        gtm: {},
        colorbg: '#EB0613',
        colorLabel: '#FFFFFF',
    });

    const slots: { icon?: any; default?: any } = useSlots();

    const emit = defineEmits<{
        (e: 'click', data: MouseEvent): void;
    }>();

    const customStyles = computed(() =>
        ['solid-custom', 'outline-custom'].includes(props.styling)
            ? {
                  '--custom-bg': props.colorBg,
                  '--custom-label': props.colorLabel,
              }
            : {},
    );

    const handleClick = (evt: MouseEvent) => {
        emit('click', evt);
    };

    const kindType = computed(() => {
        if (props.kind === 'link') return NuxtLink;
        if (props.kind === 'decorative') return 'span';
        return 'button';
    });
    const isTextStyle = computed(() => ['text', 'text-secondary', 'text-invert'].includes(props.styling));
</script>

<template>
    <component
        :is="kindType"
        :to="kind === 'link' ? $helpers.generateLocalePath(to, skipLocalPath) : undefined"
        :tartget="target"
        :disabled="disabled || loading"
        :class="[
            'atm-button relative inline-flex items-center justify-center gap-2 rounded-md px-4 text-center text-base font-bold transition-colors disabled:pointer-events-none disabled:opacity-40 max-md:w-full md:max-w-96',
            {
                'h-10 text-xs': size === 'small',
                'h-12': size === 'medium',
                '[&:not(:disabled)]:hover:underline': isTextStyle,
                'border-2': !isTextStyle,
                'text-woom-red': styling === 'text-secondary',
                'text-woom-white': styling === 'text-invert',
                'border-woom-red bg-woom-red text-woom-white [&:not(:disabled)]:hover:border-woom-red-hover [&:not(:disabled)]:hover:bg-woom-red-hover':
                    styling === 'solid',
                'border-woom-black bg-woom-black text-woom-white [&:not(:disabled)]:hover:border-black [&:not(:disabled)]:hover:bg-black':
                    styling === 'solid-secondary',
                'border-[--custom-bg] bg-[--custom-bg] text-[--custom-label]': styling === 'solid-custom',
                'bg-woom-white text-woom-black [&:not(:disabled)]:hover:bg-woom-grey-mid': styling === 'solid-invert',
                'border-transparent text-woom-white before:absolute before:inset-0 before:skew-y-3 before:rounded-full before:bg-woom-black before:transition-all before:duration-300 after:absolute after:inset-0 after:rounded-full after:bg-woom-red disabled:before:hidden md:before:skew-y-6 [&:not(:disabled)]:hover:before:-skew-y-3 md:[&:not(:disabled)]:hover:before:-skew-y-6':
                    styling === 'solid-rounded',
                'border-woom-black text-woom-black [&:not(:disabled)]:hover:bg-woom-grey-mid': styling === 'outline',
                'border-woom-white text-woom-white [&:not(:disabled)]:hover:bg-woom-grey-moon': styling === 'outline-invert',
                'border-[--custom-bg] text-[--custom-label]': styling === 'outline-custom',
                'flex-row-reverse': iconRight,
                'w-full !max-w-none': block,
            },
        ]"
        :style="customStyles"
        @click="handleClick">
        <atm-loader-circular
            v-show="loading"
            :class="['absolute z-10', { hidden: !loading }]" />

        <span
            v-if="slots.icon"
            :class="['z-10 fill-current', { 'opacity-0': loading }]"
            :aria-hidden="loading">
            <slot name="icon"></slot>
        </span>
        <span
            :class="['z-10 w-auto overflow-hidden text-ellipsis whitespace-nowrap text-center', { 'opacity-0': loading }]"
            :aria-hidden="loading">
            <slot></slot>
        </span>
    </component>
</template>

<style scoped>
    button :slotted(.icon-svg) {
        @apply h-5 w-5 flex-shrink-0 fill-current;
    }
</style>
