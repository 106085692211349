<script setup lang="ts">
    import { useToast } from 'primevue/usetoast';

    const toast = useToast();
    const { previewEnabed } = usePreview();

    onMounted(() => {
        watch(
            previewEnabed,
            async (newValue) => {
                if (!newValue) return;
                await wait(1500);
                toast.add({
                    severity: 'info',
                    summary: 'Woom is currently in preview mode.',
                    life: 3000,
                    group: 'headless',
                });
            },
            {
                immediate: true,
            },
        );
    });

    const getIconBySeverity = (severity: string) => {
        if (severity === 'success') return 'WoomIconCheck';
        if (severity === 'warn') return 'WoomIconAlert';
        if (severity === 'error') return 'WoomIconAlert';
        return 'WoomIconInformation';
    };
</script>

<template>
    <PrimeToast
        position="bottom-left"
        group="headless"
        unstyled>
        <template #container="{ message, closeCallback }">
            <div
                :class="[
                    'mt-2 flex items-center gap-2 rounded-lg border border-l-4 border-woom-grey-mid border-l-current bg-woom-white px-2 py-3 drop-shadow-md',
                    {
                        'text-woom-green-jungle': message.severity === 'success',
                        'text-woom-orange-flame': message.severity === 'warn',
                        'text-woom-red': message.severity === 'error',
                        'text-woom-blue-electric': message.severity === 'info',
                    },
                ]">
                <span class="mx-2 flex shrink-0 items-center justify-center rounded-full bg-current p-2">
                    <component
                        :is="getIconBySeverity(message.severity)"
                        class="h-5 w-5 fill-white" />
                </span>
                <span class="text-woom-black">
                    {{ message.summary }}
                </span>
                <button
                    @click="closeCallback"
                    class="ml-auto h-12 w-12 shrink-0 text-woom-black">
                    <woom-icon-close class="mx-auto h-4 w-4 fill-current" />
                </button>
            </div>
        </template>
    </PrimeToast>
</template>

<style>
    .p-toast-message-enter-active,
    .p-toast-message-leave-active {
        @apply transition duration-300 ease-out;
    }
    .p-toast-message-leave-active {
        @apply duration-150;
    }
    .p-toast-message-enter-from {
        @apply translate-y-12 opacity-0;
    }
    .p-toast-message-enter-to,
    .p-toast-message-leave-from {
        @apply translate-y-0 opacity-100;
    }
    .p-toast-message-leave-to {
        @apply -translate-y-12 opacity-0;
    }
</style>
